a {
  transition: all 0.25s ease-in;
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
}

.navbar-brand {
  text-decoration: none;
}

.ox-header__logo {

    a {

      span {
        text-transform: uppercase;
        text-decoration: none;
        color: #000;
        font-weight: 600;

        &:hover {
          text-decoration: none;
          cursor: pointer;
          color: black;
        }
      }
    }

    .foreground {
      font-family: 'canada-type-gibson', sans-serif;
      font-size: 2.4rem;
    }

    .fa-bars {
      font-size: 3.2rem;
    }

}
