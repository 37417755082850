.ox-grid {
  max-width: 100%;
  overflow: hidden;
}

.ox-grid__cols {
  display: flex;
  align-items: flex-start;
  margin: 0 0.5rem;
  transition: all 0.2s ease-in-out;
}

.ox-grid__cols--space-0.ox-grid__cols {
  margin: 0;
}

.ox-grid__cols--space-1.ox-grid__cols {
  margin: 0 0.214rem;
}

.ox-grid__cols--space-2.ox-grid__cols {
  margin: 0 0.428rem;
}

.ox-grid__cols--space-3.ox-grid__cols {
  margin: 0 0.642rem;
}

.ox-grid__cols--space-4.ox-grid__cols {
  margin: 0 0.882rem;
}

.ox-grid__cols--space-5.ox-grid__cols {
  margin: 0 1.096rem;
}

.ox-grid__cols--space-6.ox-grid__cols {
  margin: 0 1.31rem;
}

.ox-grid__cols--space-7.ox-grid__cols {
  margin: 0 1.5rem;
}

.ox-grid__col {
  flex: 0 0 100%;
  max-width: 100%;

  @include more-than(sm) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @include more-than(xl) {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }

  @include more-than-custom(1920px) {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }

  @include more-than-custom(2520px) {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }

  @include more-than-custom(3120px) {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }

  @include more-than-custom(3840px) {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }
}

.ox-grid__cols--5 .ox-grid__col {
  @include more-than(sm) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @include more-than(lg) {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }

  @include more-than(xl) {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }

  @include more-than-custom(1920px) {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }

  @include more-than-custom(2520px) {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }

  @include more-than-custom(3120px) {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }

  @include more-than-custom(3840px) {
    flex: 0 0 calc(100% / 8);
    max-width: calc(100% / 8);
  }
}

.ox-grid__cols--6 .ox-grid__col {
  @include more-than(sm) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @include more-than(md) {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }

  @include more-than(lg) {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }

  @include more-than(xl) {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }

  @include more-than-custom(1920px) {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }

  @include more-than-custom(2520px) {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }

  @include more-than-custom(3120px) {
    flex: 0 0 calc(100% / 8);
    max-width: calc(100% / 8);
  }

  @include more-than-custom(3840px) {
    flex: 0 0 calc(100% / 9);
    max-width: calc(100% / 9);
  }
}

.ox-grid__cols--7 .ox-grid__col {
  @include more-than(sm) {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }

  @include more-than(md) {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }

  @include more-than(lg) {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }

  @include more-than(xl) {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }

  @include more-than-custom(1920px) {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }

  @include more-than-custom(2520px) {
    flex: 0 0 calc(100% / 8);
    max-width: calc(100% / 8);
  }

  @include more-than-custom(3120px) {
    flex: 0 0 calc(100% / 9);
    max-width: calc(100% / 9);
  }

  @include more-than-custom(3840px) {
    flex: 0 0 calc(100% / 10);
    max-width: calc(100% / 10);
  }
}

.ox-grid__cols--8 .ox-grid__col {
  @include more-than(sm) {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }

  @include more-than(md) {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }

  @include more-than(lg) {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }

  @include more-than(xl) {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }

  @include more-than-custom(1920px) {
    flex: 0 0 calc(100% / 8);
    max-width: calc(100% / 8);
  }

  @include more-than-custom(2520px) {
    flex: 0 0 calc(100% / 9);
    max-width: calc(100% / 9);
  }

  @include more-than-custom(3120px) {
    flex: 0 0 calc(100% / 10);
    max-width: calc(100% / 10);
  }

  @include more-than-custom(3840px) {
    flex: 0 0 calc(100% / 11);
    max-width: calc(100% / 11);
  }
}

.ox-grid__cols--9 .ox-grid__col {
  flex: 0 0 calc(100% / 2);
  max-width: calc(100% / 2);

  @include more-than(sm) {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }

  @include more-than(md) {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }

  @include more-than(lg) {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }

  @include more-than(xl) {
    flex: 0 0 calc(100% / 8);
    max-width: calc(100% / 8);
  }

  @include more-than-custom(1920px) {
    flex: 0 0 calc(100% / 9);
    max-width: calc(100% / 9);
  }

  @include more-than-custom(2520px) {
    flex: 0 0 calc(100% / 10);
    max-width: calc(100% / 10);
  }

  @include more-than-custom(3120px) {
    flex: 0 0 calc(100% / 11);
    max-width: calc(100% / 11);
  }

  @include more-than-custom(3840px) {
    flex: 0 0 calc(100% / 12);
    max-width: calc(100% / 12);
  }
}

.ox-grid__cols--10 .ox-grid__col {
  flex: 0 0 calc(100% / 2);
  max-width: calc(100% / 2);

  @include more-than-custom(400px) {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }

  @include more-than(sm) {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }

  @include more-than(md) {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }

  @include more-than(lg) {
    flex: 0 0 calc(100% / 8);
    max-width: calc(100% / 8);
  }

  @include more-than(xl) {
    flex: 0 0 calc(100% / 9);
    max-width: calc(100% / 9);
  }

  @include more-than-custom(1920px) {
    flex: 0 0 calc(100% / 10);
    max-width: calc(100% / 10);
  }

  @include more-than-custom(2520px) {
    flex: 0 0 calc(100% / 11);
    max-width: calc(100% / 11);
  }

  @include more-than-custom(3120px) {
    flex: 0 0 calc(100% / 12);
    max-width: calc(100% / 12);
  }

  @include more-than-custom(3840px) {
    flex: 0 0 calc(100% / 13);
    max-width: calc(100% / 13);
  }
}

.ox-grid__cols--11 .ox-grid__col {
  flex: 0 0 calc(100% / 2);
  max-width: calc(100% / 2);

  @include more-than-custom(400px) {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }

  @include more-than(sm) {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }

  @include more-than(md) {
    flex: 0 0 calc(100% / 8);
    max-width: calc(100% / 8);
  }

  @include more-than(lg) {
    flex: 0 0 calc(100% / 9);
    max-width: calc(100% / 9);
  }

  @include more-than(xl) {
    flex: 0 0 calc(100% / 10);
    max-width: calc(100% / 10);
  }

  @include more-than-custom(1920px) {
    flex: 0 0 calc(100% / 11);
    max-width: calc(100% / 11);
  }

  @include more-than-custom(2520px) {
    flex: 0 0 calc(100% / 12);
    max-width: calc(100% / 12);
  }

  @include more-than-custom(3120px) {
    flex: 0 0 calc(100% / 13);
    max-width: calc(100% / 13);
  }

  @include more-than-custom(3840px) {
    flex: 0 0 calc(100% / 14);
    max-width: calc(100% / 14);
  }
}

.ox-grid__item {
  padding: 0;
  transition: all 0.2s ease-in-out;

  .swiper {
    .swiper-pagination {
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background: #000;
          background-color: #000;
          color: #000;
        }
      }
    }
  }
}

.ox-grid__cols--space-0 .ox-grid__item {
  padding: 0;
}

.ox-grid__cols--space-1 .ox-grid__item {
  padding: 0 0.214rem 0.428rem 0.214rem;
}

.ox-grid__cols--space-2 .ox-grid__item {
  padding: 0 0.428rem 0.856rem 0.428rem;
}

.ox-grid__cols--space-3 .ox-grid__item {
  padding: 0 0.642rem 1.284rem 0.642rem;
}

.ox-grid__cols--space-4 .ox-grid__item {
  padding: 0 0.882rem 1.764rem 0.882rem;
}

.ox-grid__cols--space-5 .ox-grid__item {
  padding: 0 1.096rem 2.192rem 1.096rem;
}

.ox-grid__cols--space-6 .ox-grid__item {
  padding: 0 1.31rem 2.62rem 1.31rem;
}

.ox-grid__cols--space-7 .ox-grid__item {
  padding: 0 1.5rem 3rem 1.5rem;
}

.ox-grid__link {
  display: block;
  overflow: hidden;
  cursor: pointer;
}

.ox-grid__ref {
  display: block;
  overflow: hidden;
  cursor: default;
}

.ox-grid__img-el {
  display: block;
  width: 100%;
  height: 100%;
}

.ox-image-div {
  min-height: 250px;
}

.ox-grid__iframe {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: calc(100% + 17px);
  height: 100%;
}

.ox-grid-loader {
  position: relative;
  height: 100vh;
}

.ox-grid-loader__lottie {
  position: absolute;
  top: calc(50% - 8rem);
  left: 50%;
  width: 75px;
  height: 75px;
  transform: translate(-50%, -50%);
}

.ox-grid__filter-found {
  font-size: 2rem;
}

.ox-grid__filter-title {
  font-weight: bold;
  text-decoration: underline;
}

[v-cloak] {
  display: none;
}
